import { MutationFunction } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';

import { getBundleId } from './bundlesUtils';
import { CartLineItemProps } from 'containers/CartPage/LineItem/CartLineItem';
import { LineItemCustomAttributeKeys } from 'data/graphql/enums';
import {
  CustomAttribute,
  ShopifyCartLineItem,
} from 'data/graphql/types.shopify';

import Logger from 'lib/utils/Logger';

import { ProductDetailViewProps } from 'containers/ProductDetailPage/types';
import { AddToCartLineItem } from 'data/graphql/types';
import {
  AttributionType,
  ProductBookmark,
  ProductBundleItem,
  ProductVariant,
} from 'types/generated/api';

type Attribution = {
  attributionId?: string;
  attributionType?: AttributionType;
};

export const createAddToCartLineItems = (
  bookmarks: ProductBookmark[] | undefined,
  productFamily: string,
  customAttributes: CustomAttribute[],
  quantity: number,
  fullSid: string,
  variant: ProductVariant
): AddToCartLineItem[] => {
  let lineItems: AddToCartLineItem[] = [];

  try {
    const { isProductBundle, productBundleItems, sku: variantSku } = variant;

    if (isProductBundle) {
      const bundleIdCustomAttribute = buildBundleIdAttributes(variantSku);

      lineItems =
        productBundleItems?.map((bundleItem: ProductBundleItem | null) => ({
          category: productFamily,
          customAttributes: [...customAttributes, bundleIdCustomAttribute],
          quantity: (bundleItem?.quantity || 1) * quantity, // multiply by quantity of selected bundles
          variantId: formatVariantIdForLineItem(bundleItem?.sid || ''),
        })) || [];
    } else {
      lineItems = [
        {
          bookmarks,
          category: productFamily,
          customAttributes,
          quantity,
          variantId: fullSid,
        },
      ];
    }
  } catch (error) {
    Logger.error(
      'Unable to create CartLineItems collection when trying to Add To Cart',
      error
    );
  }

  return lineItems;
};

export const formatVariantIdForLineItem = (variantId: string) => {
  return `gid://shopify/ProductVariant/${variantId}`;
};

export const getHandleAddToBag = (
  addToCart: MutationFunction
): ProductDetailViewProps['handleAddToBag'] => {
  return ({
    attributionId,
    attributionType,
    bookmarks,
    customAttributes,
    fullSid,
    productFamily,
    quantity,
    variant,
  }) => {
    const attributionAttributes = getAttributionAttributes({
      attributionId,
      attributionType,
    });

    let lineItemCustomAttributes: CustomAttribute[] = [];
    if (attributionAttributes) {
      lineItemCustomAttributes = lineItemCustomAttributes.concat(
        attributionAttributes
      );
    }

    if (customAttributes && !isEmpty(customAttributes)) {
      lineItemCustomAttributes = lineItemCustomAttributes.concat(
        customAttributes
      );
    }

    return addToCart({
      variables: {
        lineItems: createAddToCartLineItems(
          (bookmarks as unknown) as ProductBookmark[],
          productFamily,
          lineItemCustomAttributes,
          quantity,
          fullSid,
          (variant as unknown) as ProductVariant
        ),
      },
    });
  };
};

export const buildBundleIdAttributes = (
  variantSku: string
): CustomAttribute => ({
  key: LineItemCustomAttributeKeys.SELECTED_BUNDLE_ID,
  value: variantSku,
});

const getAttributionAttributes = ({
  attributionId,
  attributionType,
}: Attribution): CustomAttribute[] | undefined => {
  if (attributionId && attributionType) {
    return [
      { key: '_attributionId', value: attributionId },
      { key: '_attributionType', value: attributionType },
    ];
  }

  return undefined;
};

type BundleVariantForQuantitySelector = {
  productBundleItems: Array<{ quantity: number; sku: string }>;
  sku: string;
};

type LineItemSubsetForForQuantitySelector = Pick<
  ShopifyCartLineItem,
  'customAttributes' | 'quantity'
> & {
  variant: Pick<ShopifyCartLineItem['variant'], 'sku'>;
};

export const calculateNumberOfItems = (
  lineItems: LineItemSubsetForForQuantitySelector[],
  bundleVariants: BundleVariantForQuantitySelector[]
) => {
  try {
    // A mapping of bundleId to the number of times the bundle has been added to cart.
    const bundleToQuantityMap: { [bundleId: string]: number } = {};

    const numberOfLineItemsWithoutBundles = lineItems.reduce(
      (total: number, currentItem) => {
        const bundleId = getBundleId(currentItem);

        // If truthy, then the lineItem is part of a bundle
        if (bundleId) {
          const bundleItemQuantity =
            findBundleItemQuantity(currentItem.variant.sku, bundleVariants) ??
            1;
          bundleToQuantityMap[bundleId] =
            currentItem.quantity / bundleItemQuantity;
          return total;
        }

        return (total += currentItem.quantity);
      },
      0
    );

    const numberOfBundles = sum(Object.values(bundleToQuantityMap));

    return numberOfLineItemsWithoutBundles + numberOfBundles;
  } catch (error) {
    Logger.error(
      'Error trying to calculateNumberOfItems in the cart... ',
      error
    );

    return 0;
  }
};

export const findBundleItemQuantity = (
  lineItemSku: string,
  bundleVariants: BundleVariantForQuantitySelector[]
) => {
  for (const bundleVariant of bundleVariants) {
    const foundBundleItem = bundleVariant?.productBundleItems?.find(
      bundleItem => bundleItem.sku === lineItemSku
    );

    if (foundBundleItem) {
      return foundBundleItem.quantity;
    }
  }

  return undefined;
};

export const buildReactKeyFromCartLineItem = (lineItem: CartLineItemProps) => {
  const { customAttributes, sku } = lineItem;
  return `${sku}~${JSON.stringify(customAttributes)}`;
};

import { ReactElement, useEffect, useState } from 'react';

import { getAuthModalHeader } from './utils';
import LoginForm from 'components/authForms/LogInForm/LogInForm';
import SignUpForm from 'components/authForms/SignUpForm/SignUpForm';
import Modal, { ModalProps } from 'components/Modals/Modal/Modal';

import { useAppConfigContext } from 'lib/appConfig/appConfigProvider';

import styles from './AuthModal.module.scss';

export enum ScreenTypes {
  Login,
  SignUp,
}

type AuthModalProps = Omit<ModalProps, 'children'> & {
  closeModal: () => void;
  isOpen: boolean;
  onSignInSuccess?: () => void;
  onSignUpSuccess?: () => void;
  /** tracking source for analytics */
  source?: string;
  startingScreen?: ScreenTypes;
};

const AuthModal = ({
  closeModal,
  isOpen,
  onSignInSuccess,
  onSignUpSuccess,
  source,
  startingScreen,
  ...rest
}: AuthModalProps): ReactElement => {
  const [screenType, setScreenType] = useState(
    startingScreen ?? ScreenTypes.Login
  );

  const {
    appConfig: { isRewardsEnabled },
  } = useAppConfigContext();

  useEffect(() => {
    if (startingScreen !== undefined) {
      setScreenType(startingScreen);
    }
  }, [startingScreen]);

  const isLoginScreen = screenType === ScreenTypes.Login;
  const showSignUpScreen = () => setScreenType(ScreenTypes.SignUp);
  const showLoginScreen = () => setScreenType(ScreenTypes.Login);

  const headerText = getAuthModalHeader(
    isLoginScreen,
    isRewardsEnabled ? source : undefined
  );

  return (
    <Modal
      className={styles.root}
      header={headerText}
      headerClassname={styles.headerText}
      isOpen={isOpen}
      isUsingWidthOverride
      {...rest}
    >
      {isLoginScreen ? (
        <LoginForm
          isHeaderHidden
          onSignInSuccess={onSignInSuccess ? onSignInSuccess : closeModal}
          onSignUpLinkClick={showSignUpScreen}
        />
      ) : (
        <SignUpForm
          isHeaderHidden
          onLoginLinkClick={showLoginScreen}
          onSignUpSuccess={onSignUpSuccess}
          source={source}
        />
      )}
    </Modal>
  );
};

export default AuthModal;

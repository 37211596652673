import classnames from 'classnames';
import dynamic from 'next/dynamic';
import {
  HTMLAttributes,
  KeyboardEvent,
  KeyboardEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { FeaturedLink } from './FeaturedLink';
import NavigationMenuItem from './NavigationMenuItem';
const NavigationSubmenu = dynamic(() => import('./NavigationSubmenu'), {
  ssr: false,
});
import { Container } from 'components/Grid';

import { ILocalizableLink } from 'lib/contentful';

import { SlimmedSiteNavV2 } from 'types/app';

import styles from './NavigationMenu.module.scss';

export type NavigationMenuProps = {
  featuredLink?: ILocalizableLink;
  fontColor?: string;
  menuItems: SlimmedSiteNavV2[];
} & Pick<HTMLAttributes<HTMLElement>, 'className' | 'role'>;

// Navigation menu component that is located at the lower part of the page Header,
// below the Logo
const NavigationMenu = ({
  className,
  featuredLink,
  fontColor,
  menuItems,
  ...restHTMLProps
}: NavigationMenuProps): ReactElement => {
  const [
    selectedMenuItem,
    setSelectedMenuItem,
  ] = useState<SlimmedSiteNavV2 | null>(null);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const currentSubmenu = selectedMenuItem?.submenu;
  const currentMenuItemId = selectedMenuItem?.id;
  let openMenuTimeoutId: NodeJS.Timeout;

  useEffect(() => {
    // cancel the old timer, if any (clearTimeout can handle undefined and null gracefully):
    clearTimeout(openMenuTimeoutId);

    // if the menu is not yet opened - wait - to avoid unintentional open of submenu
    // if the menu is already open - switch content instantly
    const delayWhenMenuIsOpen = 0;
    const delayWhenMenuIsClosed = 500;
    const delay = isSubmenuOpen ? delayWhenMenuIsOpen : delayWhenMenuIsClosed;

    // capture currently selected menu item, to compare it when the timer runs out
    const currentMenuItem = selectedMenuItem;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    openMenuTimeoutId = setTimeout(() => {
      // if the user moved off the menu item, it will get deselected by the time this fires,
      // so we check for it before opening the menu
      const isMenuItemSelected = selectedMenuItem !== null;
      const didSelectedItemChange = selectedMenuItem !== currentMenuItem;

      setIsSubmenuOpen(isMenuItemSelected && !didSelectedItemChange);
    }, delay);

    return () => clearTimeout(openMenuTimeoutId);
  }, [selectedMenuItem]);

  // jsx-a11y requirement:
  const handleMainKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
    (event: KeyboardEvent<HTMLElement>) => {
      // see key names here: https://www.w3.org/TR/uievents-key/#keys-ui
      if (event?.key === 'Escape') {
        setSelectedMenuItem(null);
      }
    },
    [setSelectedMenuItem]
  );

  const handleMouseLeaveMenu = () => {
    setIsSubmenuOpen(false);
    setSelectedMenuItem(null);
  };

  return (
    <nav
      {...restHTMLProps}
      className={classnames(styles.root, className)}
      onClick={() => setSelectedMenuItem(null)}
      onKeyDown={handleMainKeyDown}
      onMouseLeave={handleMouseLeaveMenu}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="menubar"
    >
      <Container>
        <div className={styles.menuBar}>
          <div className={styles.menuItemsContainer}>
            <div className={styles.scrollContainer}>
              {menuItems.map((menuItemProps, index) => (
                <NavigationMenuItem
                  {...menuItemProps}
                  className={styles.menuItem}
                  fontColor={menuItemProps.textColor ?? fontColor}
                  isSelected={
                    menuItemProps.id === currentMenuItemId && isSubmenuOpen
                  }
                  key={`${menuItemProps.id}~${index}`}
                  onMouseEnter={() => setSelectedMenuItem(menuItemProps)}
                />
              ))}
            </div>

            <div
              className={styles.fadeEnd}
              onMouseEnter={() => setSelectedMenuItem(null)}
            >
              &nbsp;
            </div>
          </div>

          {featuredLink && (
            <FeaturedLink
              className={styles.featuredLink}
              fontColor={fontColor}
              linkProps={featuredLink}
            />
          )}
        </div>
      </Container>

      {currentSubmenu && selectedMenuItem && (
        <NavigationSubmenu
          {...currentSubmenu}
          className={styles.flyoutMenu}
          id={selectedMenuItem.id}
          isOpen={isSubmenuOpen}
          onMouseLeave={() => setIsSubmenuOpen(false)}
          onOverlayClick={() => setIsSubmenuOpen(false)}
        />
      )}
    </nav>
  );
};

export default NavigationMenu;

import { HTMLAttributes } from 'react';

import SideMenuMainContent from './SideMenuMainContent';

import { useAppConfigContext } from 'lib/appConfig/appConfigProvider';
import Logger from 'lib/utils/Logger';

export type SideMenuContainerProps = HTMLAttributes<HTMLDivElement>;

const SideMenuContainer = (props: SideMenuContainerProps) => {
  const { pageConfig } = useAppConfigContext();

  if (!pageConfig.showNavigationMenu) {
    return null;
  }

  try {
    return <SideMenuMainContent {...props} />;
  } catch (error) {
    Logger.error(
      'Unexpected error when trying to render SideMenuContainer',
      error
    );
    return null;
  }
};

export default SideMenuContainer;

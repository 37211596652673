import { ReactElement } from 'react';

import useNavigationMenuData from 'lib/hooks/siteFurniture/useNavigationMenuData';
import Logger from 'lib/utils/Logger';

import styles from './SideMenuMainContent.module.scss';

import NavigationMenuMobile from '../mobile/NavigationMenuMobile';

const SideMenuMainContent = (): ReactElement | null => {
  const {
    error,
    loading,
    navigationDataV2,
    sideNavigationMenuBanner,
  } = useNavigationMenuData();

  if (error) {
    Logger.warn(
      'Unable to get navigation menu data for Side Menu Main Container',
      error
    );

    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <NavigationMenuMobile
      className={styles.mainMenuPanel}
      menuItems={navigationDataV2 || []}
      sideNavigationMenuBanner={sideNavigationMenuBanner}
    />
  );
};

export default SideMenuMainContent;

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Head from 'next/head';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ScreenClassProvider } from 'react-grid-system';

import AppDownloadInterstitial from 'components/AppDownloadInterstitial/AppDownloadInterstitial';
import BugSubmissionButton from 'components/BugSubmissionButton/BugSubmissionButton';
import CartIdSynchronizer from 'components/CartIdSynchronizer/CartIdSynchronizer';
import { GridScreenSize } from 'components/Grid/setup';
import HeaderContainer from 'components/Header/HeaderContainer';
import NewsletterModal from 'components/NewsletterModal/NewsletterModal';
import SiteFurnitureProvider from 'components/SiteFurnitureProvider/SiteFurnitureProvider';
import AppBannerModal from 'containers/AppBannerModal/AppBannerModal';
import FooterContainer from 'containers/Footer/FooterContainer';
import SiteWideBannerContainer from 'containers/SiteWideBannerContainer/SiteWideBannerContainer';
import LiveShopBanner from 'explorer/components/LiveShopBanner/LiveShopBanner';

import { SiteFurnitureContextValue } from 'lib/context/SiteFurnitureContext';
import { setSourceAffiliateLinkOwner } from 'lib/cookieWrapper/sourceAffiliateLinkOwner';
import { generateMetaTags, MetaTags } from 'lib/routes/metaTags';
import { isGridDebugMode } from 'lib/utils/debug';
import Logger from 'lib/utils/Logger';

import { SiteFurniture } from 'types/app';

import styles from './Page.module.scss';

export const PAGE_ROOT_ID = 'page-root';

export type PageOptionsProps = {
  backgroundColor?: string;
  hideAppDownloadBanner?: boolean;
  homePageId: string;
  showDynamicModules?: boolean;
  showLiveShopBanner?: boolean;
  showMarketingBanner?: boolean;
  showPageFooter?: boolean;
  showPageHeader?: boolean;
  showPoweredByVerishop?: boolean;
  showSiteWideBanner?: boolean;
  suppressMarketingBanners?: boolean;
  suppressNewsletterModal?: boolean;
};

type PageProps = {
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
  dataAttributes?: { [attribute: string]: string | undefined };
  disableMetaTags?: boolean;
  heading?: string;
  metaTags?: MetaTags;
  options?: PageOptionsProps;
  searchTerm?: string;
  siteFurniture: SiteFurniture;
  title?: string;
};

const requiredMetaTags: string[] = ['canonicalUrl', 'title'];

// A base Page component that contains all common markup that is required on every page
export const Page = ({
  children,
  className,
  contentClassName,
  dataAttributes,
  disableMetaTags,
  heading,
  metaTags,
  options,
  searchTerm,
  siteFurniture,
}: PageProps): ReactElement => {
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const openNewsletterModal = useCallback(
    () => setIsNewsletterModalOpen(true),
    []
  );
  const closeNewsletterModal = useCallback(
    () => setIsNewsletterModalOpen(false),
    []
  );

  const memoizedSiteFurniture: SiteFurnitureContextValue = useMemo(() => {
    if (siteFurniture) {
      return {
        ...siteFurniture,
        available: true,
      };
    }

    return { available: false };
  }, [siteFurniture]);

  useEffect(() => {
    setSourceAffiliateLinkOwner();
    requiredMetaTags.forEach((metaTag: string, index: number) => {
      if (isEmpty(requiredMetaTags[index])) {
        Logger.warn(`Warning: meta tags do not include a ${metaTag}`);
      }
    });
  }, []);

  const {
    backgroundColor,
    hideAppDownloadBanner = true,
    showLiveShopBanner = false,
    showMarketingBanner: showMarketingBanner,
    showPageFooter = true,
    showPageHeader = true,
    showPoweredByVerishop = true,
    showSiteWideBanner = true,
    suppressMarketingBanners,
    suppressNewsletterModal,
  } = options ?? {};

  const isDebugModeEnabled = isGridDebugMode();
  const pageClassNames = classnames(styles.content, contentClassName, {
    [styles.debug]: isDebugModeEnabled,
  });

  return (
    <>
      <SiteFurnitureProvider value={memoizedSiteFurniture}>
        <ScreenClassProvider fallbackScreenClass={GridScreenSize.XSmall}>
          <Head>
            {!disableMetaTags && metaTags && generateMetaTags(metaTags)}
          </Head>
          <span className="visuallyHidden">
            <a href="#main-content">skip to main content</a>
          </span>
          {showPageHeader && (
            <HeaderContainer
              className={styles.headerContainer}
              openNewsletterModal={openNewsletterModal}
              searchTerm={searchTerm}
            />
          )}
          <div
            {...dataAttributes}
            className={classnames(
              styles.root,
              { [styles.noHeader]: !showPageHeader },
              className
            )}
            id={PAGE_ROOT_ID}
            style={{ backgroundColor }}
          >
            {!suppressNewsletterModal && (
              <NewsletterModal
                closeModal={closeNewsletterModal}
                isOpen={isNewsletterModalOpen}
                openModal={openNewsletterModal}
              />
            )}
            <div className={pageClassNames} role="main">
              {showLiveShopBanner && <LiveShopBanner />}
              {showSiteWideBanner && <SiteWideBannerContainer />}
              {showSiteWideBanner && (
                <SiteWideBannerContainer isSiteWideBanner2 />
              )}
              <div id="main-content">
                {heading && <h1 className={styles.pageHeading}>{heading}</h1>}
                {children}
              </div>
            </div>
            {showPageFooter && (
              <FooterContainer
                className={styles.footer}
                options={{
                  hideAppDownloadBanner,
                  showPoweredByVerishop,
                }}
              />
            )}
            {!suppressMarketingBanners && <AppDownloadInterstitial />}
            {showMarketingBanner && <AppBannerModal />}
          </div>
        </ScreenClassProvider>
      </SiteFurnitureProvider>
      <CartIdSynchronizer />
      <BugSubmissionButton />
    </>
  );
};

export default Page;

import classnames from 'classnames';
import { FocusEventHandler, MouseEventHandler, ReactElement } from 'react';

import { NavigationSubmenuProps } from './NavigationSubmenu';
import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { ILocalizableLink } from 'lib/contentful';

import styles from './NavigationMenuItem.module.scss';

export type NavigationMenuItemProps = {
  fontColor?: string;
  id: string;
  isSelected?: boolean;
  link: ILocalizableLink;
  onFocus?: FocusEventHandler;
  onMouseEnter?: MouseEventHandler;
  submenu?: NavigationSubmenuProps;
} & IDefaultComponentProps;

const NavigationMenuItem = ({
  className,
  fontColor,
  isSelected,
  link,
  onFocus,
  onMouseEnter,
}: NavigationMenuItemProps): ReactElement => {
  return (
    <div
      className={classnames(styles.root, className, {
        [styles.isSelected]: isSelected,
      })}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
    >
      <LocalizableLink
        className={styles.innerLink}
        dataAttributes={{ role: 'menuitem' }}
        fontColor={fontColor}
        {...link}
      />
      <div className={styles.underline} />
    </div>
  );
};

export default NavigationMenuItem;

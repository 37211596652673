import classnames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { AccountEditLink, AccountLink, LogoutLink, OrderLink } from 'lib/links';

import styles from './AccountIconMenu.module.scss';
import sharedStyles from 'components/sharedComponentStyles.module.scss';

export const OVERLAY_TEST_ID = 'account-icon-menu-overlay';

interface IAccountIconMenuProps {
  isOpen: boolean;
  onCloseClick(): void;
}

const AccountIconMenu = ({ isOpen, onCloseClick }: IAccountIconMenuProps) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    event => {
      if (
        isOpen &&
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        onCloseClick();
      }
    },
    [isOpen, onCloseClick]
  );

  const onKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Escape') {
        onCloseClick();
      }
    },
    [isOpen, onCloseClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', onKeydown, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', onKeydown, true);
    };
  }, [handleClickOutside, onKeydown]);

  return (
    <div className={classnames(styles.root, { [styles.isOpen]: isOpen })}>
      <div className={styles.menuContainer} ref={componentRef}>
        <ul>
          <li className={styles.menuItem}>
            <AccountLink passHref>
              <a className={sharedStyles.link} href="placeholder">
                My Profile
              </a>
            </AccountLink>
          </li>
          <li className={styles.menuItem}>
            <OrderLink passHref>
              <a className={sharedStyles.link} href="placeholder">
                Order History
              </a>
            </OrderLink>
          </li>
          <li className={styles.menuItem}>
            <AccountEditLink passHref>
              <a className={sharedStyles.link} href="placeholder">
                Account Settings
              </a>
            </AccountEditLink>
          </li>
          {/* <li className={styles.menuItem}>
            <AccountLink>
              <a className={sharedStyles.link}>Refer a Friend</a>
            </AccountLink>
          </li> */}
          <li className={styles.menuItemSeparator}>
            <hr />
          </li>
          <li className={styles.menuItem}>
            <LogoutLink className={sharedStyles.link} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountIconMenu;

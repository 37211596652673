import { ReactElement, useEffect, useState } from 'react';

import IDefaultComponentProps from 'components/defaultComponentProps';
import SiteWideBanner from 'components/SiteWideBanner/SiteWideBanner';

import { isBrowser } from 'lib/utils/browser';

type SiteWideBannerContainerProps = {
  isSiteWideBanner2?: boolean;
} & IDefaultComponentProps;

const DEFAULT_BANNER_DISMISSED_STORAGE_KEY = 'isSitewideBannerDismissed';

const SiteWideBannerContainer = (
  props: SiteWideBannerContainerProps
): ReactElement | null => {
  const [isRendered, setIsRendered] = useState(true);
  const bannerProps = {
    bannerKeyName: DEFAULT_BANNER_DISMISSED_STORAGE_KEY,
    ...props,
  };

  useEffect(() => {
    // Disable sitewide banner for non-verishop sites
    if (isBrowser() && window.location.host.indexOf('.verishop.') < 0) {
      setIsRendered(false);
    }
  }, []);

  // TODO [CH6300]: Use this space for getting data from Site Furniture
  return isRendered ? <SiteWideBanner {...bannerProps} /> : null;
};

export default SiteWideBannerContainer;

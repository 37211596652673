import { ApolloError, useQuery } from '@apollo/client';
import { subMinutes } from 'date-fns';

import { isLiveShopExpired } from 'explorer/containers/LiveShopContainer/utils';

import { addDays } from 'lib/utils/date';
import Logger from 'lib/utils/Logger';

import { GET_UPCOMING_LIVE_SHOPS_DATA } from 'explorer/data/liveShopDataQuery.gql';

import {
  GetUpcomingLiveShopDataQuery,
  GetUpcomingLiveShopDataQueryVariables,
  LiveShopNoAuthFieldsFragment,
  LiveShopPrivacy,
  LiveShopStatus,
  PaginationOrder,
} from 'types/generated/api';

export interface UseUpcomingLiveShopDataVariables {
  days: number;
  includeInternal?: boolean;
  limit?: number;
  skip?: boolean;
}

export interface UseUpcomingLiveShopDataResult {
  error?: ApolloError;
  loading: boolean;
  upcomingLiveShopsData?: LiveShopNoAuthFieldsFragment[];
}

export const useUpcomingLiveShopsData = ({
  days = 7,
  includeInternal = false,
  limit = 7,
  skip,
}: UseUpcomingLiveShopDataVariables): UseUpcomingLiveShopDataResult => {
  // Apprently, we can't use the exact current time (new Date()), since wich each render, it will change
  // which will cause the props to change and query will fire again...
  // ... and again... for each time change...
  // therefore, we need to round it up to a full minute to stop the infinite loops
  const now = new Date();
  const startDate = subMinutes(
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes()
      // skip seconds and milliseconds
    ),
    120 // longest stream can last two hours
  );
  const endDate = addDays(startDate, days) || startDate;

  const privacy = includeInternal
    ? [LiveShopPrivacy.INTERNAL, LiveShopPrivacy.PUBLIC]
    : [LiveShopPrivacy.PUBLIC];

  const { data, error, loading } = useQuery<
    GetUpcomingLiveShopDataQuery,
    GetUpcomingLiveShopDataQueryVariables
  >(GET_UPCOMING_LIVE_SHOPS_DATA, {
    skip,
    variables: {
      bounds: {
        end: endDate.toISOString(),
        start: startDate.toISOString(),
      },
      limit,
      order: PaginationOrder.ASC,
      privacy,
      status: [LiveShopStatus.ACTIVE, LiveShopStatus.PUBLISHED],
    },
  });

  if (error) {
    Logger.error('Unexpected error in useUpcomingLiveShopsData', error);
  }

  const filteredResult: LiveShopNoAuthFieldsFragment[] = (data?.liveShopsByDateBounded?.items?.filter(
    item => !!item && !isLiveShopExpired(item.endsAt)
  ) || []) as LiveShopNoAuthFieldsFragment[]; // removed nulls, but it still wants to validate for them

  return {
    error,
    loading,
    upcomingLiveShopsData: filteredResult,
  };
};

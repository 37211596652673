import uniqBy from 'lodash/uniqBy';
import React, { ReactElement, useMemo } from 'react';

import IDefaultComponentProps from 'components/defaultComponentProps';
import NavigationMenu, {
  NavigationMenuProps,
} from 'components/NavigationMenu/NavigationMenu';

import { useAppConfigContext } from 'lib/appConfig/appConfigProvider';
import useNavigationMenuData from 'lib/hooks/siteFurniture/useNavigationMenuData';

import { SlimmedSiteNavV2 } from 'types/app';

type NavigationMenuContainerProps = {
  NavigationMenuComponent?: React.ComponentType<NavigationMenuProps>;
  useFeaturedItems?: boolean;
} & IDefaultComponentProps;

export const NavigationMenuContainer = ({
  className,
}: NavigationMenuContainerProps): ReactElement | null => {
  const { pageConfig } = useAppConfigContext();
  const { navigationDataV2 } = useNavigationMenuData();

  const desktopNavItems = useMemo((): SlimmedSiteNavV2[] => {
    // TICKET: ECH2-1135
    // There is an issue with desktop nav items being duplicated across
    // the header. I am not able to consistently reproduce the issue, so for now,
    // filtering by unique ID will fix this for the time being until the root cause
    // is figured out since this bug is blocking page performance improves getting
    // deployed to production
    const filtered = uniqBy(
      navigationDataV2?.filter(navItem => navItem.mobileOnly === false),
      'id'
    );
    return filtered || [];
  }, [navigationDataV2]);

  if (!pageConfig.showNavigationMenu) {
    return null;
  }

  if (desktopNavItems.length === 0) {
    return null;
  }

  return (
    <NavigationMenu
      className={className}
      menuItems={desktopNavItems}
      role="navigation"
    />
  );
};

export default NavigationMenuContainer;

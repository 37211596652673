import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { SCROLL_STATE } from './HeaderContainer';
import HeaderLogo from './HeaderLogo';
import { SearchBarContainer } from './SearchBarContainer';
import UserMenu from './UserMenu';
import TextButton from 'components/Buttons/TextButton/TextButton';
import IDefaultComponentProps from 'components/defaultComponentProps';
import { Column, Container, Row } from 'components/Grid';
import InfoBar from 'components/InfoBar/InfoBar';
import SideMenuContainer from 'components/NavigationMenu/SideMenu/SideMenuContainer';
import NavigationMenuContainer from 'containers/NavigationMenu/NavigationMenuContainer';

import { useAppConfigContext } from 'lib/appConfig/appConfigProvider';

import ChevronDownIcon from 'assets/icons/ic-chevrone-down.inline.svg';

import styles from './Header.module.scss';

export const HEADER_CONTENT_ID = 'header-content-container';

type HeaderProps = {
  openNewsletterModal: () => void;
  scrollState?: SCROLL_STATE;
  searchTerm?: string;
} & IDefaultComponentProps;

const Header = ({
  className,
  openNewsletterModal,
  scrollState,
  searchTerm,
}: HeaderProps) => {
  const [isNavMenuShowing, setIsNavMenuShowing] = useState(false);
  const { pageConfig } = useAppConfigContext();
  const scrollStateCss = getScrollStateCss(scrollState, isNavMenuShowing);

  const toggleIsNavMenuShowing = useCallback(() => {
    setIsNavMenuShowing(!isNavMenuShowing);
  }, [isNavMenuShowing]);

  useEffect(() => {
    setIsNavMenuShowing(scrollState !== SCROLL_STATE.SCROLLED_DOWN);
  }, [scrollState]);

  return (
    <header
      className={classnames(
        styles.root,
        styles.withBottomShadow,
        className,
        scrollStateCss
      )}
    >
      <div className={styles.infoBar}>
        <InfoBar
          openNewsletterModal={openNewsletterModal}
          scrollState={scrollState}
        />
      </div>

      <Container>
        <Row>
          <Column xs={12}>
            <div className={styles.headerContent} id={HEADER_CONTENT_ID}>
              <div>
                <SideMenuContainer className={styles.sideMenuButton} />
              </div>
              <HeaderLogo className={styles.logo} />

              <div className={styles.departmentsDropDown}>
                {pageConfig.showNavigationMenu && (
                  <TextButton
                    className={styles.departmentsButton}
                    onClick={toggleIsNavMenuShowing}
                  >
                    Departments
                    <ChevronDownIcon className={styles.chevronDownIcon} />
                  </TextButton>
                )}
              </div>
              <SearchBarContainer
                className={styles.searchBoxContainer}
                searchTerm={searchTerm}
              />
              <div className={styles.userMenuContainer}>
                <UserMenu />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
      <NavigationMenuContainer
        className={styles.navigationMenu}
        useFeaturedItems
      />
    </header>
  );
};

Header.defaultProps = {
  showSearchBar: true,
};

export const getScrollStateCss = (
  scrollState?: SCROLL_STATE,
  isNavMenuShowing?: boolean
) => {
  let scrollStateCss = null;

  switch (scrollState) {
    case SCROLL_STATE.SCROLLED_UP: {
      scrollStateCss = styles.expanding;
      break;
    }
    case SCROLL_STATE.SCROLLED_DOWN: {
      if (isNavMenuShowing) {
        scrollStateCss = `${styles.collapsing} ${styles.navVisible}`;
      } else {
        scrollStateCss = styles.collapsing;
      }
      break;
    }
    default: // noop
  }

  return scrollStateCss;
};

export default Header;
